import Error from "next/error";
import { useRouter } from "next/router";
import { useEffect } from "react";

import * as segment from "lib/tracker/segment";

export default function ErrorPage() {
  const router = useRouter();

  useEffect(() => {
    segment.trackCustomEvent("page_404", {
      route: router.asPath,
    });
  }, []);

  return <Error statusCode={404} />;
}

ErrorPage.generatePageName = () => "404 Not Found";
